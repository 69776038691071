import React, { useState, useEffect } from "react"

import "./navbar.scss"

import Logo from '../../images/logo.svg'
import Phone from '../../images/phone.svg'
import Mail from '../../images/mail.svg'
import LinkedIn from '../../images/linkedIn.svg'
import OpenIcon from '../../images/chevron-up.svg'
import CloseIcon from '../../images/chevron-down.svg'
import SendIcon from '../../images/Send.svg'

import { useForm, ValidationError } from '@formspree/react';

// markup
const Navbar = ({ bottom, resetBottom }) => {

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  // const [bottom, setBottom] = useState(props.bottom);

  const [state, handleSubmit] = useForm("xayadwqg");


  useEffect(() => console.log('value changed!'), [bottom]);



  function toggleNav() {
    if (open) {
      const newOpen = false
      setOpen(newOpen)
      resetBottom()
    } else {
      const newOpen = true
      setOpen(newOpen)
    }
  }

  function updateEmail(event) {
    setEmail(event.target.value)
  }
  function updateMessage(event) {
    setMessage(event.target.value)
  }

  return (
    <div className={`navbar ${!state.succeeded && (open || bottom) ? "open" : ""}`} >

      <form onSubmit={handleSubmit}>
        {/* Contact */}
        {(!state.succeeded && (open || bottom)) && <div >



          {/* <label htmlFor="email">
            Email Address
          </label> */}
          
            <div className="contact">
              <textarea
                id="message"
                name="message"
                autoFocus
                className="message"
                placeholder="Ask a question"
                value={message}
                onChange={updateMessage}
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}/>
              <input
                id="email"
                type="email"
                name="email"
                className="email"
                placeholder="Your Email"
                value={email}
                onChange={updateEmail}
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              
              
            </div>


        </div>}

        {/* Toggle */}
        <div className="toggle">
          <img src={Logo} className="logo" />
          {(!state.succeeded && (message.length && email.length)) ?
            <button type="submit">
              {/* <button type="submit" disabled={state.errors}> */}
              <ion-icon name={"send"} ></ion-icon>
              {/* Submit */}
            </button>
            :
            <div>
              {state.succeeded ? 
              <p className="sent">Sent!</p> 
              :
              <ion-icon onClick={toggleNav} name={!(open || bottom) ? 'chevron-up' : 'chevron-down'} ></ion-icon>
              }
            </div>
          }
          {/* {(message.length && email.length) ? <ion-icon name="send"></ion-icon> : <ion-icon name={(open || bottom) ? 'chevron-down' : 'chevron-up'}></ion-icon>} */}
        </div>
      </form>
    </div>
  )
}

export default Navbar
