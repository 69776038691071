import React, { useState, useEffect, useCallback } from "react"

// Packages
import { Helmet } from "react-helmet";
import ReactPaginate from 'react-paginate';
import Slider from "react-slick";
import CurrencyInput from 'react-currency-input-field';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import axios from 'axios';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useMediaPredicate } from "react-media-hook";

// Styles
import "normalize.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "./styles.scss"

// Assets
import Michigan from '../images/Michigan.svg'
import SendIcon from '../images/Send.svg'
import RemoveIcon from '../images/remove.svg'

// Components
import Chart from "../components/Chart/Chart"
import Navbar from "../components/Navbar/Navbar"


// Markup
const IndexPage = () => {

  // State
  const [refund, setRefund] = useState(0);
  const [expense, setExpense] = useState(0);
  const [service, setService] = useState(0);
  const [equipment, setEquipment] = useState(0);
  const [fee, setFee] = useState(0);
  const [adding, setAdding] = useState(false);
  const [sites, setSites] = useState([]);

  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [bottom, setBottom] = useState(false);

  const size = useWindowSize();

  // Hook
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      // only execute all the code below in client side
      if (typeof window !== 'undefined') {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  // Utility
  var currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  var clients = []

  const clientImages = importAll(require.context('../images/clients', false, /\.(png|jpe?g|svg)$/));

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item);
      clients.push(item.replace('./', ''))
    }
    );
    return images;
  }

  // const [width, setWidth] = useState(window.innerWidth);
  // const [height, setHeight] = useState(window.innerHeight);
  // const updateDimensions = () => {
  //   setWidth(window.innerWidth);
  //   setHeight(window.innerHeight);
  // }
  // useEffect(() => {
  //   window.addEventListener("resize", updateDimensions);
  //   return () => window.removeEventListener("resize", updateDimensions);
  // }, []);



  // Calc Refund
  function calculateRefund(value, name) {
    if (!value) {
      value = 0;
    } else {
      value = parseFloat(value)
    }

    console.log(typeof (value))
    console.log(name)
    console.log(value)
    switch (name) {
      case 'Service':
        setService(value);
        setExpense(value + equipment + fee);
        setRefund((value + equipment + fee) * .65);
        break;
      case 'Equipment':
        setEquipment(value);
        setExpense(service + value + fee);
        setRefund((service + value + fee) * .65);
        break;
      case 'Fee':
        setFee(value);
        setExpense(service + equipment + value);
        setRefund((service + equipment + value) * .65);
        break;
      default:
        console.log('input error')
    }


    // console.log(refund,expense,service,equipment,fee)
  }

  useBottomScrollListener(atBottom);

  function atBottom(stuff) {
    console.log(bottom)
    setBottom(true)
  }

  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  }

  var refundOption = {
    tooltip: {
      trigger: 'item'
    },

    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: '80%',
        // left: 'center',
        right: 0,
        color: ['#358A60', '#424C63', '#2C3851', '#071435'],
        tooltip: {
          formatter: function (params, ticket, callback) {
            let message = ''
            if (refund) {
              const full = params.value / .35
              const discount = full * .65;
              const final = full - discount;

              if (params.name != 'Healthcare\nConnect\nFund') {
                message = `<strong style="color: #14213D">${params.name}</strong><br><strong style="color: #14213D">${currency.format(full)}</strong> annually<br><strong style="color: #14213D">${currency.format(final)}</strong> after discount<br>`
              } else {
                message = `<strong style="color: #14213D">${params.name}</strong><br> <strong style="color: #036D38">${currency.format(params.value)}</strong> annual discount`
              }
            } else {
              message = `<strong style="color: #14213D">${params.name}</strong><br>Enter Annual Expense`
            }



            return message;
          },
          extraCssText: 'box-shadow: 0px 0px 40px rgb(20 33 61 / 10%);',
          borderWidth: 0,
          padding: 15,
          textStyle: {
            fontFamily: 'Open Sans',
            fontSize: 17
          }
        },
        data: [
          {
            value: refund || 46800,
            name: 'Healthcare\nConnect\nFund',
            label: {
              show: false
            }
          },
          {
            value: !refund ? 1875 : fee * .35, name: 'Fees', label: {
              show: false
            }
          },
          {
            value: !refund ? 4450 : equipment * .35, name: 'Equipment', label: {
              show: false
            }
          },
          //   (enteredEquipmentCost*.35)
          {
            value: !refund ? 18875 : service * .35, name: 'Broadband\nService', label: {
              show: false
            }
          },

        ],
        emphasis: {
          // itemStyle: {
          //   borderWidths: 0,
          //   shadowBlur: 28,
          //   shadowOffsetX: 0,
          //   shadowColor: 'rgba(0, 0, 0, 0.1)'
          // }
        }
      }
    ]
  }

  var expenseOption = {
    tooltip: {
      trigger: 'item'
    },

    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: '80%',
        // left: 'center',
        right: 0,
        color: ['#071435', '#424C63', '#2C3851'],
        tooltip: {
          formatter: function (params, ticket, callback) {
            let message = '';

            if (refund) {

              const full = params.value / .35
              const discount = full * .65;
              const final = full - discount;

              message = `<strong style="color: #14213D">${params.name}</strong><br><strong style="color: #14213D">${currency.format(full)}</strong> annually<br><strong style="color: #14213D">${currency.format(final)}</strong> after discount<br>`
            } else {
              message = `<strong style="color: #14213D">${params.name}</strong><br>Enter Annual Expense`
            }

            return message;
          },
          extraCssText: 'box-shadow: 0px 0px 40px rgb(20 33 61 / 10%);',
          borderWidth: 0,
          padding: 15,
          textStyle: {
            fontFamily: 'Open Sans',
            fontSize: 17
          }
        },
        data: [
          {
            value: !refund ? 18875 : service * .35, name: 'Broadband\nService', label: {
              show: false
            }
          },
          {
            value: !refund ? 1875 : fee * .35, name: 'Fees', label: {
              show: false
            }
          },
          {
            value: !refund ? 4450 : equipment * .35, name: 'Equipment', label: {
              show: false
            }
          }

        ],
        emphasis: {
          // itemStyle: {
          //   borderWidths: 0,
          //   shadowBlur: 28,
          //   shadowOffsetX: 0,
          //   shadowColor: 'rgba(0, 0, 0, 0.1)'
          // }
        }
      }
    ]
  }

  function Items({ currentItems }) {
    let reversed = []
    if (currentItems) {
      reversed = currentItems.reverse()
    }

    return (
      <>
        {currentItems &&
          reversed.map((item, index) => (
            <div className="item"><span>{item.name}</span><ion-icon onClick={() => { removeSite(item.name) }} name="close-outline"></ion-icon></div>
          ))}
      </>
    );

  }

  function PaginatedItems({ itemsPerPage }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);


    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(sites.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(sites.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, sites]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % sites.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
      setCurrentPage(event.selected);
    }

    return (
      <>
        <Items currentItems={currentItems} />
        {pageCount > 1 && <ReactPaginate
          breakLabel="..."
          nextLabel={currentPage != pageCount - 1 ? '>' : ''}
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          previousLabel={currentPage != 0 ? '<' : ''}
          renderOnZeroPageCount={null}
        />}

      </>
    );
  }


  function handleChange(address) {
    setAddress(address)
  }


  function removeSite(siteName) {
    setSites(sites.filter(site => site.name !== siteName))
  }

  function getPlaceDetails(place_id) {

    axios.get(`https://api.track.toggl.com/reports/api/v2/summary?user_agent=api_test&workspace_id=5135632&grouping=clients&subgrouping=projects`, {
      auth: {
        username: '2ba707b47ab0f29cc1f0386104763aa3',
        password: 'api_token'
      }
    })
      .then(res => {
        console.log(JSON.stringify(res))
      }).catch(error => console.error(error))

    axios.get(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${place_id}&fields=name%2Cformatted_address&key=AIzaSyCXsWLYQ_e3B4NeKuMgU_6JolklwK372OI`)
      .then(res => {
        setSites(prevSites => ([res.data.result, ...prevSites,]))
        setAddress('')
      })
      .catch(error => console.error('Error', error))
  }

  function handleSelect(address) {
    console.log(address)
    geocodeByAddress(address)
      .then(results => getPlaceDetails(results[0].place_id))
      .catch(error => console.error('Error', error))
  }



  // useEffect(() => console.log('mobile',mobile), [mobile]);

  return (
    <div className="wrapper">


      {size.width > 1200 ?
        <div className="desktop">
          <Navbar bottom={bottom} resetBottom={() => { setBottom(false) }}></Navbar>
          <div className="max-width">
            <div className="refund">
              <div className="text">
                <div className="banner">
                  <p className="refundText">Rural Healthcare Providers Receive A 65% Discount</p>
                  <p className="expenseText">On Broadband Service, Equipment, and Fees</p>
                  <p className="subtitle">Through The <span>Healthcare Connect Fund</span> Program.</p>
                </div>
                <div className="calculate">
                  <h1>Calculate Your Discount</h1>
                  <p>Enter your estimated annual expenses for <span>Broadband Service</span>, <span>Equipment</span>, and <span>Install and Upgrade Fees</span> to calculate your refund from the Healthcare Connect Fund.</p>
                </div>
                <div className="expenses">
                  <h1>Broadband Service</h1>
                  <p>The Healthcare Connect Fund Program provides a 65% discount on all broadband internet connectivity expenses located in both rural and in some instances, non-rural.  These connections can be primary connections as well as redundant back-up connections.</p>

                  <h1>Equipment</h1>
                  <p>A 65% discount is available on network equipment necessary to  manage, control, or maintain an eligible service or a dedicated health care broadband network. Examples of qualifying equipment include routers/switches, computers/servers used exclusively for network management, network management software, and firewalls.</p>

                  <h1>Install And Upgrade Fees</h1>
                  <p>A 65% discount is available on installation of qualifying new services as well as fees charged for bandwith upgrades.</p>
                </div>
              </div>
              <div className="calculator">
                <div className="under-glass">
                  <div className="field-background"></div>
                </div>
                <div className="glass"></div>
                <div className="elements">
                  <div className="refund-chart">
                    <Chart option={refundOption}></Chart>
                  </div>
                  <div className="refund-annual">
                    <div className="total">
                      <span style={{ opacity: refund ? 1 : .5 }}>{currency.format(refund)}</span>
                    </div>
                    <div className="label">
                      <span>Annual Discount</span>
                    </div>
                  </div>
                  <div className="expense-fields">
                    <div className="fields">
                      <CurrencyInput
                        id="input-example"
                        name="Service"
                        prefix="$"
                        placeholder="$0"
                        decimalsLimit={0}
                        onValueChange={(value, name) => calculateRefund(value, name)}
                      />
                      <CurrencyInput
                        id="input-example2"
                        name="Equipment"
                        prefix="$"
                        placeholder="$0"
                        decimalsLimit={0}
                        onValueChange={(value, name) => calculateRefund(value, name)}
                      />
                      <CurrencyInput
                        id="input-example3"
                        name="Fee"
                        prefix="$"
                        placeholder="$0"
                        decimalsLimit={0}
                        onValueChange={(value, name) => calculateRefund(value, name)}
                      />
                    </div>
                    <div className="labels">
                      <div className="label"><span>Broadband Service</span></div>
                      <div className="label"><span>Equipment</span></div>
                      <div className="label"><span>Fees</span></div>



                    </div>
                  </div>
                  <div className="expense-annual">
                    <div className="total">
                      <span style={{ opacity: expense ? 1 : .5 }}>{currency.format(expense)}</span>
                    </div>
                    <div className="label">
                      <span>Annual Expense</span>
                    </div>
                  </div>
                  <div className="expense-chart">
                    <Chart option={expenseOption}></Chart>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* gradient start */}

          <div className="max-width">

            <div className="clients">
              <div className="text">
                <h1>Our Clients</h1>
                <p>RHC Consulting serves a wide variety of hospitals, rural health care clinics, health departments, and community mental health centers at over 100 locations throughout Michigan.</p>
                <p>It is our goal to relieve the administrative burden and stress of managing the application process for busy healthcare facilities, while maximizing funding to help alleviate the rising costs of telecommunication services.</p>
              </div>
              <div className="state">
                {/* <iframe src="//batchgeo.com/map/cf41f8ad1513941cdf27bb7ce88e6533" frameborder="0" width="100%" height="360" sandbox="allow-top-navigation allow-scripts allow-popups allow-same-origin allow-modals allow-forms" style={{border:'1px solid #aaa;'}}></iframe> */}
                <img src={Michigan} className="michigan" />
              </div>
              <div className="scroll">
                <Slider {...settings} className="clients-slider">

                  {clients.map((client, index) => (<div><img className="client" src={clientImages[client].default} key={client} alt="" /></div>))}
                </Slider>
              </div>
            </div>
            <div className="eligibility">



              {/* <div className="report">
  <div className={adding ? "wrapper adding" : "wrapper"}>
    <button disabled={!email.length} >
      <span>Request Eligibility Report</span>
      <img src={SendIcon} alt="" />
    </button>
    <input type="text" placeholder="Recipient Email"
      value={email} onChange={(e) => { setEmail(e.target.value) }}
    />
    <div className="item" onClick={() => { setAdding(true) }}><span>Add Another Recipient</span><ion-icon name="add-outline"></ion-icon></div>

    {!adding && <div className="item" style={{ marginBottom: 30 }} onClick={() => { setAdding(true) }}><span>Add HCP Sites (optional)</span><ion-icon name="add-outline"></ion-icon></div>}
    {adding && <div className={sites.length > 4 ? 'hcp-list paged' : 'hcp-list'}>
      {sites.length > 4 && <span className="site-count">{sites.length} Sites</span>}
      {sites.length > 0 && <PaginatedItems itemsPerPage={4} />}
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: {
            country: ['us'],
          },
          fields: ['(formatted_address)', '(geometry)', '(name)']

        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="card" style={loading || address.length ? {borderRadius: '0 0 9px 9px'} : {borderRadius: '9px'}}>
            <input
              autoFocus
              {...getInputProps({
                placeholder: 'HCP Site Name',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

    </div>}
  </div>
</div> */}



              <div className="text">
                <h1>Are my HCP Sites Eligible?</h1>
                <p>The following entities are eligible for funding provided through the <span>Healthcare Connect Fund Program</span>:</p>
                <ul>
                  <li>Not-for-profit <span>hospitals</span></li>
                  <li>Dedicated <span>emergency rooms</span> of rural for-profit hospitals </li>
                  <li>Local <span>health departments</span> or agencies </li>
                  <li><span>Community mental health</span> centers</li>
                  <li>Rural <span>health clinics</span></li>
                  <li>Skilled <span>nursing facilities</span> (SNF)</li>
                  <li>Community health centers or health care centers providing <span>healthcare to migrants</span></li>
                  <li>Post-secondary educational institutions offering healthcare instruction, such as teaching hospitals or <span>medical schools</span></li>
                </ul>
                <br></br>
                {/* <p style={{marginTop: 0}}>RHC Consulting provides a free eligibility report:</p> */}
                <br /><strong>CALL</strong><span>  989.614.7393</span><br /><br />
                <strong>EMAIL</strong><span>  info@rhcconsult.com</span>
              </div>
            </div>
            {/* <div className="footer">

              <h1>66 Days 12 Hours 14 Seconds</h1>
              <p>(Days left in window for current year) RHC Consulting serves a wide variety of hospitals, rural health care clinics, health departments, and community mental health centers at over 100 locations throughout Michigan.</p>
              <p>(process simple risk free)It is our goal to relieve the administrative burden and stress of managing the application process for busy healthcare facilities, while maximizing funding to help alleviate the rising costs of telecommunication services.</p>
              <br/><br/><strong>CALL</strong><span>989.732.7393</span><br/><br/>
              <strong>EMAIL</strong><span>theresa@rhc.consulting</span>
            </div> */}
          </div>

        </div>
        :
        <div className="mobile">
          <Navbar mobile={true} bottom={bottom} resetBottom={() => { setBottom(false) }}></Navbar>
          <div className="refund">
            <div className="text">
              <div className="banner">
                <p className="refundText">Rural<br /> Healthcare <br /> Providers <br />Receive <br />A 65% Discount</p>
                <p className="expenseText">On Broadband <br /> Service, <br />Equipment, <br />and Fees</p>
                <p className="subtitle">Through The <span>Healthcare Connect Fund</span> Program.</p>
              </div>
            </div>
          </div>
          <div className="gradient">
            <div className="expenses">
              <h1>Broadband Service</h1>
              <p>The Healthcare Connect Fund Program provides a 65% discount on all broadband internet connectivity expenses located in both rural and in some instances, non-rural.  These connections can be primary connections as well as redundant back-up connections.</p>

              <h1>Equipment</h1>
              <p>A 65% discount is available on network equipment necessary to  manage, control, or maintain an eligible service or a dedicated health care broadband network. Examples of qualifying equipment include routers/switches, computers/servers used exclusively for network management, network management software, and firewalls.</p>

              <h1>Install And Upgrade Fees</h1>
              <p>A 65% discount is available on installation of qualifying new services as well as fees charged for bandwith upgrades.</p>
            </div>
          </div>

          <div className="gradients">
            <div className="top-gradient"></div>
            <div className="mid-gradient"></div>
            <div className="bottom-gradient"></div>
            <div className="calc-wrap">
              <div className="calculator">
                <div className="under-glass">
                  <div className="field-background"></div>
                </div>
                <div className="glass"></div>
                <div className="elements">
                  <div className="refund-chart">
                    <Chart option={refundOption}></Chart>
                  </div>
                  <div className="refund-annual">
                    <div className="total">
                      <span style={{ opacity: refund ? 1 : .5 }}>{currency.format(refund)}</span>
                    </div>
                    <div className="label">
                      <span>Annual Discount</span>
                    </div>
                  </div>
                  <div className="expense-fields">
                    <div className="fields">
                      <CurrencyInput
                        id="input-example"
                        name="Service"
                        prefix="$"
                        placeholder="$0"
                        decimalsLimit={0}
                        onValueChange={(value, name) => calculateRefund(value, name)}
                      />
                      <CurrencyInput
                        id="input-example2"
                        name="Equipment"
                        prefix="$"
                        placeholder="$0"
                        decimalsLimit={0}
                        onValueChange={(value, name) => calculateRefund(value, name)}
                      />
                      <CurrencyInput
                        id="input-example3"
                        name="Fee"
                        prefix="$"
                        placeholder="$0"
                        decimalsLimit={0}
                        onValueChange={(value, name) => calculateRefund(value, name)}
                      />
                    </div>
                    <div className="labels">
                      <div className="label"><span>Broadband Service</span></div>
                      <div className="label"><span>Equipment</span></div>
                      <div className="label"><span>Fees</span></div>



                    </div>
                  </div>
                  <div className="expense-annual">
                    <div className="total">
                      <span style={{ opacity: expense ? 1 : .5 }}>{currency.format(expense)}</span>
                    </div>
                    <div className="label">
                      <span>Annual Expense</span>
                    </div>
                  </div>
                  <div className="expense-chart">
                    <Chart option={expenseOption}></Chart>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="gradient-reverse">
            <div className="clients">


              <div className="text-wrap">

                <div className="text">
                  <h1>Our Clients</h1>
                  <p>RHC Consulting serves a wide variety of hospitals, rural health care clinics, health departments, and community mental health centers at over 100 locations throughout Michigan.</p>
                  <p>It is our goal to relieve the administrative burden and stress of managing the application process for busy healthcare facilities, while maximizing funding to help alleviate the rising costs of telecommunication services.</p>
                </div>
                <div className="state">
                  <img src={Michigan} className="michigan" />
                </div>
                <div className="scroll">
                  <Slider {...settings} className="clients-slider">

                    {clients.map((client, index) => (<div><img className="client" src={clientImages[client].default} key={client} alt="" /></div>))}
                  </Slider>
                </div>
              </div>



            </div>
            <div className="eligibility">
              {/* <div className="report">
              <div className={adding ? "wrapper adding" : "wrapper"}>
                <button disabled={!email.length} >
                  <span>Request Eligibility Report</span>
                  <img src={SendIcon} alt="" />
                </button>
                <input type="text" placeholder="Recipient Email"
                  value={email} onChange={(e) => { setEmail(e.target.value) }}
                />
                <div className="item" onClick={() => { setAdding(true) }}><span>Add Another Recipient</span><ion-icon name="add-outline"></ion-icon></div>

                {!adding && <div className="item" style={{ marginBottom: 20 }} onClick={() => { setAdding(true) }}><span>Add HCP Sites (optional)</span><ion-icon name="add-outline"></ion-icon></div>}
                {adding && <div className={sites.length > 4 ? 'hcp-list paged' : 'hcp-list'}>
                  {sites.length > 4 && <span className="site-count">{sites.length} Sites</span>}
                  {sites.length > 0 && <PaginatedItems itemsPerPage={4} />}
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    searchOptions={{
                      componentRestrictions: {
                        country: ['us'],
                      },
                      fields: ['(formatted_address)', '(geometry)', '(name)']

                    }}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div >
                        <input
                          autoFocus
                          {...getInputProps({
                            placeholder: 'HCP Site Name',
                            className: 'location-search-input',
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>

                </div>}
              </div>
            </div> */}



              <div className="text">
                <h1>Are my HCP Sites Eligible?</h1>
                <p>The following entities are eligible for funding provided through the <span>Healthcare Connect Fund Program</span>:</p>
                <ul>
                  <li>Not-for-profit <span>hospitals</span></li>
                  <li>Dedicated <span>emergency rooms</span> of rural for-profit hospitals </li>
                  <li>Local <span>health departments</span> or agencies </li>
                  <li><span>Community mental health</span> centers</li>
                  <li>Rural <span>health clinics</span></li>
                  <li>Skilled <span>nursing facilities</span> (SNF)</li>
                  <li>Community health centers or health care centers providing <span>healthcare to migrants</span></li>
                  <li>Post-secondary educational institutions offering healthcare instruction, such as teaching hospitals or <span>medical schools</span></li>
                </ul>
                {/* <p style={{marginTop: 20}}>RHC Consulting provides a free eligibility report:</p> */}
                <div style={{ padding: '10px 20px', marginTop: 40 }}>

                  <strong>CALL</strong><span>  989.614.7393</span>
                </div>
                <div style={{ padding: '10px 20px' }}>
                  <strong>EMAIL</strong><span>  info@rhcconsult.com</span>
                </div>
              </div>
            </div>



          </div>
          {/* <div className="footer">

         

        </div> */}


        </div>
      }

      <Helmet>

        {/* ionicons */}
        <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
        <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>

        {/* Places */}
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCXsWLYQ_e3B4NeKuMgU_6JolklwK372OI&libraries=places"></script>
      </Helmet>
    </div>



  )
}

export default IndexPage
